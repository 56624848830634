/* eslint-disable @typescript-eslint/no-unused-vars */
// Profile Details are the Public Facing Properties of Users.
import { EntityState } from '@ngrx/entity';
import { signalStore, withState, withHooks } from '@ngrx/signals';

import { IKillBillCatalogProduct, ISyblChatWithPagination, ISyblSubsciption } from '@sybl/feature-sybl-models';

import {
  IPersonAdvancedSearch,
  SyblUserProfile,
} from '@sybl/feature-users-models';
import { ILocation } from '@sybl/common-models';
import { withSubscriptionStoreMethods } from './subscription-store-methods';
import { withEntities } from '@ngrx/signals/entities';

interface ISyblCustomer{
  agency_name: string,
  agency_id: string, // Check if it exists...
}
/**
 * @ngrx/entity provides a predefined interface for handling
 * a structured dictionary of records. This interface
 * includes an array of ids, and a dictionary of the provided
 * model type by id. This interface is extended to include
 * any additional interface properties.
 */
export interface SubscriptionStoreState {
  entities: {[name:string]:ISyblSubsciption};
  catalogProducts: Array<IKillBillCatalogProduct>;
  highlightsText: string;
  tenant_logo: string;
  videoText: string;
  videoId: string;
  imageId: string;
  middleText: string;
  tenant: string;
  loaded: boolean;
  ids: Array<string>;
  selectedPlan: string;
  selectedTime: string;
  agency_name: string,
  agency_id: string,
  email: string,
  firstName: string,
  lastName: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  postalCode: string,
  creditCard: string,
  cardType: string,
  expDate: string,
  cvc: string,
  productId: string,
  nameOnCard: string,
  calls: number;
  name: string;
  price: number;
  months: number,
  licenseType: string;
  stripeTestId: string;
  killBillProdId: string;
  stripeProdId: string;
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
*/
const initialState: SubscriptionStoreState = {
  loaded: true,
  catalogProducts: [],
  highlightsText: '',
  tenant_logo: undefined,
  videoText: '',
  videoId: '',
  imageId: '',
  middleText: '',
  tenant: 'sybl',
  entities: {
    Premier:{
      min_seats: 1,
      optionsText:'',
      title:'Premier',
      date_time: new Date(),
      order: 0,
      name: 'Premier',
      uuid: '',
      discount_code: '',
      display_text: '',
      months: 12,
      unit_monthly_price:undefined, 
      unit_yearly_price: undefined,
      discount_number: undefined,
    },
    PlusEarlyAdopter:{
      min_seats: 1,
      optionsText:'',
      title:'Plus',
      date_time: new Date(),
      order: 1,
      name: 'PlusEarlyAdopter',
      uuid: '',
      discount_code: '',
      display_text: '',
      months: 12,
      unit_monthly_price: undefined, 
      unit_yearly_price:undefined,
      discount_number: undefined,
    },
    Plus:{
      min_seats: 1,
      optionsText: '',
      title:'Plus',
      date_time: new Date(),
      order: 2,
      name: 'Plus',
      uuid: '',
      discount_code: '',
      display_text: '',
      months: 12,
      unit_monthly_price: undefined, 
      unit_yearly_price: undefined,
      discount_number: undefined,
    },
    Enterprise:{
      optionsText: '',
      min_seats: 1,
      title:'Enterprise',
      date_time: new Date(),
      order: 2,
      name: 'Enterprise',
      uuid: '',
      discount_code: '',
      display_text: '',
      months: 12,
      unit_monthly_price: undefined, 
      unit_yearly_price: undefined,
      discount_number: undefined,
    },
  },
  selectedTime: '',
  selectedPlan: undefined,
  ids: [
    'Premier',
    'PlusEarlyAdopter',
    'Plus',
    'Enterprise'
  ],
  agency_name: '',
  agency_id: '',
  email: '',
  firstName: '',
  lastName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  creditCard: '',
  cardType: '',
  expDate: '',
  cvc: '',
  productId: '',
  nameOnCard: '',
  calls:0,
  name: '',
  months: 12,
  licenseType: undefined,
  killBillProdId: '',
  stripeTestId: '',
  stripeProdId: '',
  price: undefined,
};

export const SyblSubscriptionStore = signalStore(
  { providedIn: 'root' },
  //withDevtools('subscriptionStore'), // <-- add this
  withState(initialState),
  withSubscriptionStoreMethods(),
  //withEntities(),
  withHooks({
    onInit(store) {
      //if (store.loadFromLocalStorage()) {
      // return;
      // }
      // store.load(1);
    },
  })
);
