// Important Appears starting anything with 'not_' will result in undefined when trying to look it up??
export enum DocumentStatusEnum{
    'pristine',
    'changes_made',
    'sending_to_server',
    'awaiting_approval',    
    'accepted',
    'declined',
    'completed',
    'error',
    'receiving_from_server',
    'receive_complete',
    'flagged',
    'review_needed',
    'revision_needed',          
    'blocked',                  
    'authorization_failed',
    'immutable',                
    'using_local_version',     
    'unauthorized' 
}
