/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { BehaviorSubject, pipe, switchMap, tap, lastValueFrom } from 'rxjs';
import {
  AuthBackendService,
  AuthCookieService,
  BrowserInfoService,
} from '@sybl/feature-auth-data-access';
import { AuthStatusState } from '../reducers';
import { ISessionResponse } from '@sybl/feature-auth-models';
import { UserProfileStore } from '@sybl/feature-users-state';
import {
  UsersWebsocketStore,
  SyblWebsocketStore,
} from '@sybl/feature-websocket-state';
import { SyblAccountManagementHttpService, SyblAgencyStore } from '@sybl/feature-sybl-state';
import { SyblSubscriptionStore } from '@sybl/feature-subscriptions-state'
export function withAuthStatusMethods() {
  return signalStoreFeature(
    { state: type<AuthStatusState>() },
    //todoService ad authService after ---> )
    withMethods(
      (
        state,
        authBackendService = inject(AuthBackendService),
        authCookieService = inject(AuthCookieService),
        browserInfo = inject(BrowserInfoService),
        userProfileStore: any = inject(UserProfileStore),
        userWebsocket: any = inject(UsersWebsocketStore),
        syblWebsocket: any = inject(SyblWebsocketStore),
        syblAgencyStore: any = inject(SyblAgencyStore),
        subscriptionStore: any = inject(SyblSubscriptionStore),
        syblHttpService: any = inject(SyblAccountManagementHttpService),

      ) => ({
        async login(email: string, password: string) {
          patchState(state, { loading: true });

          await authBackendService
            .login(email, password)
            .subscribe(async (response) => {

              if (response.user !== undefined && response.user !== null) {
                const user = response.user;
                patchState(state, {
                  user_id: user.user_id,
                  permissions: user.permissions,
                  email: user.email,
                  username: user.username,
                  jwtToken: user.jwtToken,
                  user: user,
                  loading: false,
                  loggedIn: true,
                  wrongPassword: false,
                  timeOutSeconds: 0,
                  lockUntil: 0,
                });
                // Signed in Get New Websocker
                const name = 'auth';
                const cookieObject = {
                  sessionId: user.sessionId,
                  user_id: user.user_id,
                  browserInfo: browserInfo.browserInfo(),
                  jwtToken: user.jwtToken,
                  refreshToken: user.refreshToken,
                };

                const value = JSON.stringify(cookieObject);

                // Setting initial cookie expiration on login to one week.
                const expires = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);

                authCookieService.setCookie(
                  name,
                  value,
                  expires,
                  '/',
                  '',
                  true,
                  'None'
                );

                
                if (user.permissions >= 3) {
                  userWebsocket.initializeWebSocket(user);
                  syblWebsocket.initializeWebSocket(user);
                  syblWebsocket.getAgencyForUser(user.user_id)
                }
                
                return true;
              } else {
                let timeOutSeconds = 0;
                const authAttempts = state.authAttempts();

                if (state.authAttempts() > 3) {
                  timeOutSeconds = Math.pow(2, state.authAttempts() - 3);
                }

                patchState(state, {
                  timeOutSeconds: timeOutSeconds,
                  authAttempts: authAttempts,
                  errors: response.errors,
                  loading: false,
                  wrongPassword: true,
                });
                return false;
              }
            })
        },
        setReturnToUrl(url: string) {
          return patchState(state, { returnToUrl: url });
        },

        logoutUser() {
          authBackendService.logoutUser();
          userWebsocket.logout();
          userProfileStore.logout();
          syblWebsocket.logout();
          syblAgencyStore.logout();
          
          patchState(state, {
            user: undefined,
            avatarUndefined: false,
            username: '',
            user_id: '',
            permissions: 1,
            email: '',
            jwtToken: undefined,
            avatar: state.avatarUrl() + state.defaultAvatar(),
          });
        },
        async checkStatus() {
          await authBackendService
            .checkAuthStatus()
            .subscribe(async (authResponse: ISessionResponse | any) => {
              if (authResponse.user !== undefined) {
                if (authResponse.user_id !== null) {
                  const user = authResponse.user;
                  patchState(state, {
                    user_id: user.user_id,
                    permissions: user.permissions,
                    email: user.email,
                    username: user.username,
                    jwtToken: user.jwtToken,
                    user: user,
                    loading: false,
                    loggedIn: true,
                    wrongPassword: false,
                    timeOutSeconds: 0,
                    lockUntil: 0,
                  });

                  // Signed in Get New Websocker
                  const name = 'auth';
                  const cookieObject = {
                    sessionId: user.sessionId,
                    user_id: user.user_id,
                    browserInfo: browserInfo.browserInfo(),
                    jwtToken: user.jwtToken,
                    refreshToken: user.refreshToken,
                  };

                  const value = JSON.stringify(cookieObject);

                  // Setting initial cookie expiration on login to one week.
                  const expires = new Date(
                    Date.now() + 7 * 24 * 60 * 60 * 1000
                  );

                  authCookieService.setCookie(
                    name,
                    value,
                    expires,
                    '/',
                    '',
                    true,
                    'None'
                  );

                  if (userWebsocket.connected()) {
                    return true;
                  } else {
                    userWebsocket.initializeWebSocket(user);
                    // Don't initiate websocket for sybl unless user is registered
                    if (user.permissions >= 3) {
                      syblWebsocket.initializeWebSocket(user);
                      syblWebsocket.getAgencyForUser(user.user_id)

                    }
                  }
               
  

                  return true;
                } else {
                  let timeOutSeconds = 0;
                  const authAttempts = state.authAttempts();

                  if (state.authAttempts() > 3) {
                    timeOutSeconds = Math.pow(2, state.authAttempts() - 3);
                  }

                  patchState(state, {
                    timeOutSeconds: timeOutSeconds,
                    authAttempts: authAttempts,
                    //@ts-ignore
                    errors: response.errors,
                    loading: false,
                    wrongPassword: true,
                  });
                  return false;
                }
              } else if (authResponse.error !== null) {
                return {};
              }
              return {};
            });
        },
        async signUp(signupDetails: any) {

          await authBackendService
            .signUp(signupDetails)
            .subscribe((response) => {
              if (response.user_id !== undefined) {
                const user = response;

                patchState(state, {
                  user_id: user.user_id,
                  permissions: user.permissions,
                  email: user.email,
                  username: user.username,
                  jwtToken: user.jwtToken,
                  user: user,
                  loading: false,
                  loggedIn: true,
                  wrongPassword: false,
                  timeOutSeconds: 0,
                  lockUntil: 0,
                });

                userWebsocket.initializeWebSocket(user);
                syblWebsocket.initializeWebSocket(user);
                syblWebsocket.getAgencyForUser(user.user_id)

              }
            });
        },
        createUserFromTeamAccount(user: { sessionId: any; user_id: any; jwtToken: any; refreshToken: any; permissions: any; email: any; username: any; }) {

          const name = 'auth';
          const cookieObject = {
            sessionId: user.sessionId,
            user_id: user.user_id,
            browserInfo: browserInfo.browserInfo(),
            jwtToken: user.jwtToken,
            refreshToken: user.refreshToken,
          };

          const value = JSON.stringify(cookieObject);

          // Setting initial cookie expiration on login to one week.
          const expires = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);

          authCookieService.setCookie(
            name,
            value,
            expires,
            '/',
            '',
            true,
            'None'
          );

          patchState(state, {
            user_id: user.user_id,
            permissions: user.permissions,
            email: user.email,
            username: user.username,
            jwtToken: user.jwtToken,
            user: user,
            loading: false,
            loggedIn: true,
            wrongPassword: false,
            timeOutSeconds: 0,
            lockUntil: 0,
          });

          userWebsocket.initializeWebSocket(user);
          syblWebsocket.initializeWebSocket(user);
          syblWebsocket.getAgencyForUser(user.user_id)

        },
        setLoading(value: boolean){
          return patchState(state,{ loading:value})
        },

        createIndividualFromTeamAccount(user: { sessionId: any; user_id: any; jwtToken: any; refreshToken: any; permissions: any; email: any; username: any; }){

          const name = 'auth';
          const cookieObject = {
            sessionId: user.sessionId,
            user_id: user.user_id,
            browserInfo: browserInfo.browserInfo(),
            jwtToken: user.jwtToken,
            refreshToken: user.refreshToken,
          };

          const value = JSON.stringify(cookieObject);

          // Setting initial cookie expiration on login to one week.
          const expires = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);

          authCookieService.setCookie(
            name,
            value,
            expires,
            '/',
            '',
            true,
            'None'
          );
          
          patchState(state, {
            user_id: user.user_id,
            permissions: user.permissions,
            email: user.email,
            username: user.username,
            jwtToken: user.jwtToken,
            user: user,
            loading: false,
            loggedIn: true,
            wrongPassword: false,
            timeOutSeconds: 0,
            lockUntil: 0,
          });

          userWebsocket.initializeWebSocket(user);
          syblWebsocket.initializeWebSocket(user);
          syblWebsocket.getAgencyForUser(user.user_id)

        }      
      }),
    )
  );
}
