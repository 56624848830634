/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { BehaviorSubject, pipe, switchMap, tap } from 'rxjs';
import {
  AuthBackendService,
  AuthCookieService,
  BrowserInfoService,
} from '@sybl/feature-auth-data-access';
import { UserProfileState } from './user-profile-store';
import { ISessionResponse } from '@sybl/feature-auth-models';
import { IWebSocketMessage, WebSocketMessage } from '@sybl/feature-websocket-models';
import { DocumentStatusEnum, IDocumentSaveError } from '@sybl/feature-documents-models';
//import { TodoState } from './todo.state';
//scrollPos$: BehaviorSubject;
import { UUID } from '@sybl/common-models';

export function withUserProfileMethods() {
  return signalStoreFeature(
    { state: type<UserProfileState>(),
    },
    //todoService ad authService after ---> )
    withMethods((state,
    
        ) => ({
      udpatedUserProfile(userProfile) {

        if (userProfile != null) {
          const master_id = userProfile._id;
          const avatar = userProfile.avatar;
          let avatarUndefined = true;
          const email = userProfile.email;
          const username = userProfile.username;
          const user_id = userProfile.user_id;
          const profileId = userProfile._id;
          const blockedUsers = userProfile.blockedUsers;
          const mutedUsers = userProfile.mutedUsers;
          const currentLatitude = userProfile.currentLatitude;
          const currentLongitude = userProfile.currentLongitude;

          const newEntities = { ...state.entities(), [user_id]: userProfile };
          const ids = Array.from(new Set([...state.ids(), user_id]));

          if (avatar !== null && avatar !== '' && avatar !== undefined) {
            avatarUndefined = false;
          }
           patchState(state, {
            ids: ids,
            profileId: profileId,
            avatar: avatar,
            avatarUndefined: avatarUndefined,
            selectedUserId: userProfile._id,
            entities: newEntities,
            email: email,
            currentLatitude: currentLatitude,
            currentLongitude: currentLongitude,
            // location:{ latitude: currentLatitude, longitude: currentLongitude},
            username: username,
            user_id: user_id,
            blockedUsers: blockedUsers,
            mutedUsers: mutedUsers,
            userProfile: userProfile,
          });


        }
      },
      updateUserProfileFromServer(webSocketMessage){
        const master_id = webSocketMessage.payload.master_id;
        const userProfile = webSocketMessage.payload;
        const document = webSocketMessage.document;
        const documentStatus: DocumentStatusEnum = document.status;
        // TODO IMPORTANT NEED TO HANDLE THE WAY I AM DOING DOCUMENTS.
        if (documentStatus === DocumentStatusEnum['accepted']) {
            // TODO IMPORTANT Move documentFacade stuff 
            //    this.documentFacade.updateServerDocument(userProfile);
        }
        this.udpatedUserProfile(userProfile)
      },
      
      setBaseInfo(firstName, lastName, email){
        patchState(state,{
          firstName: firstName,
          lastName: lastName,
          email: email,
        })
      },
      logout() {
        patchState(state, {
          avatar: '',
          avatarUndefined: true,
          email: '',
          user_id: null,
          username: '',
          displayName: '',
          blockedUsers: [],
          mutedUsers: [],
          userProfile: undefined,
        });
      },
      
      typeAhead(text: string, user_id, jwtToken ) {
        const message = new WebSocketMessage({
            user_id: user_id,
            webSocketUrl: 'profiles/typeAhead',
            uuid: new UUID().UUID(),
            msg: 'ATTEMPT',
            payload: { text: text },
            jwtToken: jwtToken,
            status: 1
        })

        return// websocketService.sendUserWebsocketMessage(message)
    },

      async hoot(email: string, password: string) {
        // Placeholder
        //patchState(state, { loading: true })
      },


    }))
  );
}
