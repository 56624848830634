/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject, Inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';

import { UsersWebsocketState } from './users-websocket';
import { IAuth } from '@sybl/feature-auth-models';
import { IUserProfileBase } from '@sybl/feature-users-models';
import { io } from 'socket.io-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { UUID } from '@sybl/common-models';
import { IWebSocketMessage, WebSocketMessage } from '@sybl/feature-websocket-models';
import { UserProfileStore} from '@sybl/feature-users-state'
import { IDocumentSaveError } from '@sybl/feature-documents-models';

export function withUserWebsocketMethods() {
  // inTimeout$: Observable<any> = this.store.select(WebsocketQuery.selectUserWebsocketInTimeout)
  let wsObservable: Observable<any>;
  const connected$ = new BehaviorSubject<boolean>(false);
  const debounceConnection$ = new BehaviorSubject<any>('')

  let webSocket!: any;

  return signalStoreFeature(
    { state: type<UsersWebsocketState>() },
    withMethods((state,
                 userProfileStore:any = inject(UserProfileStore)
    ) => ({
      initializeWebSocket(authUser: IAuth): void {
        const appUrls = state.appUrls;

        let auth: any = {};

        if (authUser && authUser.signUp === true) {
          const jwtToken = authUser.jwtToken;
          const email = authUser.email;
          const firstName = authUser.firstName;
          const lastName = authUser.lastName;
          const sessionId = authUser.sessionId;
          const user_id = authUser.user_id;
          const username = authUser.username;
          const permissions = authUser.permissions;

          auth = {
            signUp: true,
            jwtToken: jwtToken,
            email: email,
            firstName: firstName,
            lastName: lastName,
            sessionId: sessionId,
            user_id: user_id,
            username: username,
            permissons: permissions,
          }

        } else {
          auth =authUser;
        }

        try {
          const webSocketUrl = appUrls.USERS_WEB_SOCKET_ADDRESS(); // ?params=' + jsonWebToken;

          webSocket = io(webSocketUrl, {
            withCredentials: true,
            reconnectionDelayMax: 10000,
            auth: {
              token: auth,
            },
          });

          webSocket.io.on("open", () => {
            debounceConnection$.next(true)
            patchState(state,{connected:true})
            webSocket.io.engine.transport.on("pollComplete", () => {
              return
            });

          })

          webSocket.io.on("close", e => {
            patchState(state,{connected:false})

            if (e.wasClean) {
              connected$.next(false)
            } else {
              connected$.next(false)
            }
          });

          webSocket.on("error", (e) => {
            const params = {
              uuid: new UUID().UUID(),
              payload: { error: e },
              status: 11,
              connected: false,
              msg: 'WEB_SOCKET_CONNECTION_ERROR',
              webSocketUrl: 'err'
            };
            const webSocketMessage = new WebSocketMessage(params);
          })

          webSocket.on("message", (message) => {
            this.dataRouter(message, authUser, webSocket)
          })

        } catch (err) {
          // TODO HANDLE ERROR
        }
      },
      sendMessage(webSocketMessage: IWebSocketMessage){
        if (webSocket) {
          webSocket.send(webSocketMessage)
        }
      },
      logout() {
        if (webSocket) {
          userProfileStore.logout()
          webSocket.close()
        }

      },
      dataRouter(message: IWebSocketMessage, authUser, webSocket) {
        switch (message.webSocketUrl) {
          case "auth/auth_attempt": {
            // WebSocket Must first be connected to and then auth details sent and profile received.
            if (message.msg === 'WEB_SOCKET_CONNECTION_SUCCESS') {
              patchState(state,{connected:true})
              const jwtToken = authUser.jwtToken;

              const message = new WebSocketMessage({
                webSocketUrl: 'profiles/get_logged_in_user',
                uuid: new UUID().UUID(),
                msg: 'GET_LOGGED_IN_USER_ATTEMPT',
                payload: authUser,
                jwtToken: jwtToken,
                status: 1
              })
              return webSocket.send(message)
            } else if (message.msg === 'WEB_SOCKET_CONNECTION_ERROR') {

              const payload = message.payload;
              const error = payload.error;
              console.error("Add ReTry Service Here")
              //  return this.userLoginFacade.loggedInUserAuthFail(error)

            }

            break;
          }
          case "profiles/save": {
            if (message.msg === "SUCCESS") {

              //  this.appFacade.saveSuccess();

              return userProfileStore.updateUserProfileFromServer(message)

            } else if (message.msg === "ERROR") {
              const errorPayload: IDocumentSaveError = message.payload;
              const master_id = errorPayload.master_id;
              const status = errorPayload.status;
              return {}//this.documentsFacade.updateDocumentStatus(master_id, status)
            }

            break
          }
          case 'profiles/typeAhead':{
            return {}//this.userProfileFacade.searchResults(message.payload)
          }

          case 'profiles/get_basket_of_users':
          case 'profiles/get_profile_by_id':
          case "profiles/get_by_id": {
            if (message.msg === "SUCCESS") {
              return {}// this.userProfileFacade.receivedUsersFromServer(message.payload)
            } else if (message.msg === "GET_USER_PROFILES_BY_ID_ERROR") {
              return {}
            }

            break
          }
          case "profiles/get_logged_in_user": {
            if (message.msg === "GET_LOGGED_IN_USER_SUCCESS") {
              const userProfile = message.payload.userProfile;
              return userProfileStore.udpatedUserProfile(userProfile)

            } else if (message.msg === "GET_LOGGED_IN_USER_ERROR") {
              return {}// this.userProfileFacade.getLoggedInUserProfileError(message)

            }
            break
          }

          default: break;
        }
        return {};
      },
      /*
            checkWebSocket() {
              if (this.webSocket === undefined) {

                return 0;
              } else return this.webSocket.readyState;
            },

            join(room: string) {
              // auto rejoin after reconnect mechanism
              this.connected$.subscribe(connected => {
                if (connected) {
                  this.webSocket.send('Drop this');
                  //emit('join', {room});
                }
              });
            },

            disconnect() {
              //
              //if (this.webSocket) {
          //
              //  this.webSocket.close();
              //}
          //
              //const closeEvent: any = {
              //  typeArg: 'co',
              //  canBubbleArg: true,
              //  cancelableArg: true,
              //  wasCleanArg: true,
              //  codeArg: 12,
              //  reasonArg: 'NYB'
              //};
          //
              ////close reasons wasClean: boolean; code: number; reason: string; target: WebSocket }
              ////  this.webSocket.onclose({code:1. reason:'c', wasClean:true, initCloseEvent:closeEvent });
              //this.connected$.next(false);
              //this.store.dispatch(StateOfComedyUserWebsocketConnectionActions.UserWebsocketDisconnectSuccess())

            },

            sendMessageAttempt(webSocketMessage: IWebSocketMessage) {
              this.store.dispatch(StateOfComedyUserWebsocketMessageActions.UserWebsocketMessageSendAttempt({ webSocketMessage }))
              return
            },

            websocketGetMessage(webSocketMessage: IWebSocketMessage) {

              this.store.dispatch(StateOfComedyUserWebsocketMessageActions.UserWebsocketGetMessage({ webSocketMessage }))
              return
            },

            sendMessage(webSocketMessage: IWebSocketMessage) {
              //
              const stringyData = JSON.stringify(webSocketMessage);
              try {
                this.webSocket.send(stringyData);
                const params = { ...webSocketMessage, status: 2 };

                return new WebSocketMessage(params);
              } catch {

                const params = {
                  ...webSocketMessage,
                  status: 10,
                  msg: 'ERROR_SENDING_WEBSOCKET_MESSAGE'
                };
                if(webSocketMessage !== undefined){

                  return this.store.dispatch(StateOfComedyUserWebsocketMessageActions.UserWebsocketMessageSendToPending({webSocketMessage}))
                }

                //return new WebSocketMessage(params);
              }
            },

            webSocketConnectionAttempt(auth: IUserProfile) {

              return this.store.dispatch(StateOfComedyUserWebsocketConnectionActions.UserWebsocketConnectionAttempt({ auth }))
            },



            webSocketConnectionError(webSocketMessage: IWebSocketMessage) {
              return this.store.dispatch(StateOfComedyUserWebsocketConnectionActions.UserWebsocketConnectionError({ webSocketMessage }))

            },

            unAuthorizedWebSocket(){
              const webSocketUrl = this.appUrls.API_WEB_SOCKET_ADDRESS // ?params=' + jsonWebToken;
              this.webSocket = io(webSocketUrl, {
                  withCredentials: true,
                  reconnectionDelayMax: 10000,
                  auth:{
                      //token: jsonWebToken
                  }
              })

              this.webSocket.io.on("open", (event) => {
                  this.webSocket.io.engine.transport.on("pollComplete", () => {

                      // const request = this.webSocket.io.engine.transport.pollXhr.xhr;
                      // const cookieHeader = request.getResponseHeader("set-cookie");
                      // if (!cookieHeader) {
                      //   return;
                      // }
                      // cookieHeader.forEach(cookieString => {
                      //   if (cookieString.includes(`${COOKIE_NAME}=`)) {
                      //     const cookie = parse(cookieString);
                      //     this.webSocket.io.opts.extraHeaders = {
                      //       cookie: `${COOKIE_NAME}=${cookie[COOKIE_NAME]}`
                      //     }
                      //   }
                      // });
                  });
                  // Socket.io opens up three times, need to debounce the initialization
                  this.debounceConnection$.next(true)
              });

              this.webSocket.io.on("close", e => {

                  this.connected$.next(false);

                  if (e.wasClean) {
                      this.connected$.next(false)
                  } else {
                      this.connected$.next(false)
                  }
              });

              this.webSocket.on("error",(e) => {

                  const params = {
                      uuid: new UUID().UUID(),
                      payload: { error: e },
                      status: 11,
                      connected: false,
                      msg: 'WEB_SOCKET_CONNECTION_ERROR',
                      webSocketUrl: 'err'
                  };

                  const webSocketMessage = new WebSocketMessage(params);

                  this.store.dispatch(
                      StateOfComedyUserWebsocketConnectionActions.UserWebsocketConnectionError({ webSocketMessage })
                  );

              });

              this.webSocket.on("message", (message) => {
                  //const stringyData = event.data;
                  const webSocketMessage: IWebSocketMessage = JSON.parse(message);

                  return this.store.dispatch(
                    StateOfComedyUserWebsocketMessageActions.UserWebsocketMessageReceived({ webSocketMessage })
                  );
              })
              */
    }))
  );
}
