/* eslint-disable @typescript-eslint/no-unused-vars */
// Profile Details are the Public Facing Properties of Users.
import { EntityState } from '@ngrx/entity';
import { signalStore, withState, withHooks } from '@ngrx/signals';



import {
  IPersonAdvancedSearch,
  SyblUserProfile,
} from '@sybl/feature-users-models';
import { ILocation } from '@sybl/common-models';
import { withUserProfileMethods } from './user-profile-methods';
import { withEntities } from '@ngrx/signals/entities';
export const USER_PROFILE_INFO_FEATURE_KEY = 'user-profile-info';

/**
 * @ngrx/entity provides a predefined interface for handling
 * a structured dictionary of records. This interface
 * includes an array of ids, and a dictionary of the provided
 * model type by id. This interface is extended to include
 * any additional interface properties.
 */
export interface UserProfileState {
  ids: Array<string>;
  entities: any;
  profileId: string;
  selectedUserId: string;
  avatar: string | undefined;
  avatarUndefined: boolean;
  user_id: string;
  email: string;
  location: ILocation;
  results: Array<any>;
  username: string;
  blockedUsers: Array<string> | undefined;
  mutedUsers: Array<string> | undefined;
  userProfile: SyblUserProfile | null;

  currentLatitude: number | null;
  currentLongitude: number | null;
  firstName: string;
  lastName: string;
  personalAddress1: string;
  personalAddress2: string;
  personalCity: string;
  personalCountry: string;
  personalState: string;
  personalLongitude: any;
  personalLatitude: any;
  personalGpsLocation: any;
  personalPhone: string;
  personalPostalCode: string;
  officeAddress1: string;
  officeAddress2: string;
  officeCity: string;
  officeState: string;
  officeCountry: string;
  officeLongitude: any;
  officeLatitude: any;
  officeGpsLocation: any;
  officePhone: string;
  officePostalCode: string;
  date_time: Date;
  dateModified: Date;
  displayName: string;
  signUpFrom?: string;
  securityQuestion: string;
  securityAnswer: string;
  agency_name: string;
  agency_role: string;
  agency_id: string;
  selectedUuid: string;
  loaded: boolean;
  advancedSearchForm: IPersonAdvancedSearch | null;
  advancedSearchValues: any;
  master_ids: Array<string>;
  selectedMasterId: string;
  sortDirection: string;
  sortBy: string;
  searchOperator: string;
  searchMultipleProperties: string;
  totalCount: number;
  pageSize: number;
  pageIndex: number;
  sort: boolean;
  moreToLoad: boolean;
  maxPageIndex: number;
  lastId: string;
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
const initialState: UserProfileState = {
  entities: {},
  selectedUserId: '',
  ids: [],
  avatar: undefined,
  avatarUndefined: true,
  email: '',
  firstName: '',
  lastName: '',
  currentLatitude: null,
  currentLongitude: null,
  user_id: '',
  profileId: '',
  username: '',
  displayName: '',
  userProfile: null,
  results: [],
  blockedUsers: [],
  mutedUsers: [],
  location: {},
  selectedUuid: '',
  loaded: false,
  advancedSearchForm: undefined,
  advancedSearchValues: undefined,
  master_ids: [],
  selectedMasterId: '',
  sortDirection: '',
  sortBy: '',
  personalAddress1: '',
  personalAddress2: '',
  personalCity: '',
  personalCountry: '',
  personalState: '',
  personalLongitude: '',
  personalLatitude: '',
  personalGpsLocation: '',
  personalPhone: '',
  personalPostalCode: '',
  officeAddress1: '',
  officeAddress2: '',
  officeCity: '',
  officeState: '',
  officeCountry: '',
  officeLongitude: '',
  officeLatitude: '',
  officeGpsLocation: '',
  officePhone: '',
  officePostalCode: '',
  searchOperator: '',
  date_time: null,
  dateModified: null,
  searchMultipleProperties: '',
  totalCount: 0,
  pageSize: 0,
  pageIndex: 0,
  sort: false,
  moreToLoad: false,
  maxPageIndex: 0,
  lastId: '',
  securityQuestion: null,
  securityAnswer: null,
  agency_name: '',
  agency_role: '',
  agency_id: '',
};

export const UserProfileStore = signalStore(
  { providedIn: 'root' },
//  withDevtools('userProfiles'), // <-- add this
  withState(initialState),
  withUserProfileMethods(),
  withHooks({
    onInit(store) {
      //if (store.loadFromLocalStorage()) {
      // return;
      // }
      // store.load(1);
    },
  })
);
