
export enum PermissionsEnum{
    'guest-api',
    'guest',
    'registered-api',
    'registered',
    'moderator',
    'restricted_moderators',
    'employee-one',
    'employee-two',
    'employee-three',
    'employee-four',
    'employee-five',
    'admin',
    'super'
}